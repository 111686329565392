import React, { useState } from "react";
import "./Navbar.css";
import { FiMenu, FiX } from "react-icons/fi";

const Navbar = ({ navbarLinks }) => {
  const [menuClick, setMenuClick] = useState(true);
  const toggleMenuClick = () => {
    setMenuClick(!menuClick);
  };
  return (
    <nav className="navbar">
      <span className="navbar_logo">Gosh Tour</span>

      {menuClick ? (
        <FiMenu size={25} className="navbar_menu" onClick={toggleMenuClick} />
      ) : (
        <FiX size={25} className="navbar_menu" onClick={toggleMenuClick} />
      )}

      <ul
        className={
          menuClick ? "navbar_list" : "navbar_list navbar_list--active"
        }
      >
        {navbarLinks.map((item) => {
          return (
            <li className="navbar_item" key={item.title}>
              <a className="navbar_link" href={item.url}>
                {item.title}
              </a>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default Navbar;
